<template>
  <div>
    <pdf-viewer ref="pdf" />
  </div>
</template>

<script>
import { showImages } from 'vue-img-viewr'
import PdfViewer from './PdfViewer.vue'

export default {
  components: {
    PdfViewer,
  },
  methods: {
    show(type, doc) {
      if (type === 'application/pdf') {
        this.$refs.pdf.show(doc)
      } else if (type.includes('image')) {
        showImages({
          urls: [doc],
          index: 0,
        })
      } else {
        window.open(doc, '_blank').focus()
      }
    },
  },
}
</script>

<style>

</style>
